<!--企业土地详情页-->
<template>
  <div class="bestPerson">
    <div class="breadcrumb w-1400">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/resource' }"
          >资源集市</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/enterpriseResource' }"
          >企业资源</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/enterpriseLandList' }"
          >企业土地列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>企业土地详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="e-h2 w-1400">
      {{ pageData.title }}
    </div>
    <div class="p-list w-1400">
      <div class="floor1 clearfix">
        <el-row :gutter="40">
          <el-col :span="13">
            <div class="f1-pic fl">
              <el-image
                style="width: 100%; height: 100%"
                fit="cover"
                :src="baseUrl + pageData.photoUrls"
              ></el-image>
            </div>
          </el-col>
          <el-col :span="11">
            <div class="floor foorl1 fl">
              <div class="floor-h3">
                <i class="iconfont icon-fuzhushuxian"></i> 土地名称
              </div>
              <div class="floor-text f1-r" v-html="pageData.name"></div>
            </div>
            <div class="floor foorl1 fl">
              <div class="floor-h3">
                <i class="iconfont icon-fuzhushuxian"></i> 土地面积
              </div>
              <div class="floor-text f1-r" v-html="pageData.area + '㎡'"></div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="floor2 mt-10">
        <div class="floor-h3">
          <i class="iconfont icon-fuzhushuxian"></i> 土地介绍
        </div>
        <div class="floor-text licheng">
          {{ pageData.introduction }}
        </div>
      </div>
      <div class="floor3 mt-10">
        <div class="floor-h3">
          <i class="iconfont icon-fuzhushuxian"></i> 土地所属企业简介
        </div>
        <div class="floor-text">{{ pageData.enterpriseInfo }}</div>
      </div>
      <div class="floor4 mt-10">
        <div class="floor-h3">
          <i class="iconfont icon-fuzhushuxian"></i> 土地图片
        </div>
        <el-row :gutter="30">
          <el-col :span="6" v-for="itemv in enterpriselandData" :key="itemv.id">
            <div class="f4-pic">
              <el-image
                style="width: 100%; heihgt: 100%"
                fit="cover"
                :src="baseUrl + itemv.photoUrls[0]"
              ></el-image>
              <!-- <el-image :src="baseUrl+item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image> -->
            </div>
            <!-- <div class="f4-title ellipsis">11111111111</div> -->
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { GetOneEnterpriseLand, GetEnterpriseLand } from "@/api/ziyuanjishi";
export default {
  data() {
    return {
      pageData: {
        name: "",
        title: "",
        photoUrls: "",
        introduction: "",
        area: "",
      },
      enterpriselandData: [],
      enterpriselandParams: { currentPage: 1, pageSize: 8 },
    };
  },
  computed: {
    baseUrl: () => process.env.VUE_APP_PHOTO_URL,
    regHtml: () => regHtml,
  },
  methods: {
    getDetail() {
      GetOneEnterpriseLand({ id: this.$route.query.id }).then((res) => {
        const data = res.data.data;
        data.photoUrls = data.photoUrls.split(",")[0];
        this.pageData = data;
      });
    },
    getEnterPriseLandList() {
      GetEnterpriseLand(this.enterpriselandParams).then((res) => {
        const data = res.data.data.list;
        data.forEach((element) => {
          element.photoUrls = element.photoUrls.split(",");
        });
        this.enterpriselandData = data;
      });
    },
  },
  mounted() {
    this.getDetail();
    this.getEnterPriseLandList();
  },
};
</script>
<style scoped>
.f4-title {
  font-size: 18px;
  text-align: center;
  line-height: 32px;
}
.f4-pic {
  /* width: 280px; */
  height: 190px;
  overflow: hidden;
}
.floor-text.licheng {
  padding-left: 30px;
  text-indent: 0px;
}
.foorl1 {
  width: calc(100% - 170px);
  height: 240px;
}
.floor-text {
  font-size: 16px;
  line-height: 30px;
  text-indent: 30px;
}
.f1-pic {
  width: 100%;
  height: 500px;
  overflow: hidden;
}
.e-h2 {
  font-size: 30px;
  font-weight: 60px;
}
.floor {
  padding: 20px;
}
.floor-h3 i {
  font-size: 22px;
}
.floor-h3 {
  line-height: 42px;
  font-size: 22px;
  color: #0e6ac1;
}
.bestPerson {
  font-family: "微软雅黑";
}
.breadcrumb >>> .el-breadcrumb .is-link,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,
.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner {
  font-size: 18px;
  font-family: "微软雅黑";
  color: #0e6ac1;
}
.breadcrumb {
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #0e6ac1;
  margin-bottom: 50px;
}
</style>